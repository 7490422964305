import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  GridFlex,
  Container,
  Box,
  Title,
  Hero,
  Img,
  Markdown,
} from '@components';
import { getColorCycle } from '@helpers';

import theme from '../components/styled/theme';

const ItemWrapper = styled.div`
  position: relative;
  ${(props) => props.index % 2 === 0 && `
    background: ${theme.colors.background.blue};
  `}
`;

// const Skew = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
//     preserveAspectRatio="none"
//     style={{
//       position: 'absolute',
//       bottom: 0,
//       height: '10vh',
//       width: '100%',
//     }}
//   >
//     <polygon fill="white" points="0,100 100,0 100,100" />
//   </svg>
// );

const OfferPage = ({ data }) => {
  const { frontmatter = {} } = data.markdownRemark;

  const {
    hero,
    items,
    seo,
  } = frontmatter;

  return (
    <>
      <Hero {...hero} seo={seo} />
      {items.map(({
        title, markdown, additional, image, id,
      }, index) => {
        const isEven = index % 2 === 0;
        return (
          <ItemWrapper
            key={title}
            index={index}
            id={id}
          >
            <Container>
              <GridFlex alignItems="center" py={[2, 3, 4]}>
                {image && (
                  <Box
                    width={[1, 1, 1 / 2]}
                    order={[1, 1, isEven ? 0 : 1]}
                  >
                    <Img {...image} />
                  </Box>
                )}
                <Box
                  width={image ? [1, 1, 1 / 2] : 1}
                  maxWidth={!image ? theme.widths.small : undefined}
                  mx={!image ? 'auto' : 0}
                  py={[1, 2, 3]}
                  order={[0, 0, isEven ? 1 : 0]}
                  textAlign={['center', 'center', 'initial']}
                >
                  <Title
                    tag="h4"
                    color={getColorCycle(index)}
                    mb={[1, 2, 4]}
                  >
                    {title}
                  </Title>
                  <Markdown>
                    {markdown}
                  </Markdown>
                  {additional && (
                    <Box mt={[1, 2, 3]}>
                      {additional.map((info) => (
                        <div key={`${title}-${info.title}`}>
                          <strong>
                            {info.title}
                            {': '}
                          </strong>
                          {info.text}
                        </div>
                      ))}
                    </Box>
                  )}
                </Box>
              </GridFlex>
            </Container>
          </ItemWrapper>
        );
      })}
    </>
  );
};

OfferPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default OfferPage;

export const offerPageQuery = graphql`
  query OfferPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        items {
          title
          markdown
          id
          additional {
            title
            text
          }
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
